<template>
  <layout v-load="loading">
    <div class="container">
      <div class="card">
        <div class="card-body" v-if="token && !wrongToken && !isFilled">
          <h4 class="card-title">PROFILE</h4>
          <form @submit="handleSubmit()" class="needs-validation">
            <div class="row mb-5">
              <div class="col-md-3">
                <AvatarUploader
                    v-if="profile"
                    :avatar="profile.photo"
                    @onChange="handleAvatarChange"
                />
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="validationCustom01">Surname</label>
                      <input
                          id="validationCustom01"
                          v-model="form.last_name"
                          type="text"
                          class="form-control"
                          placeholder="Surname"
                          required
                          :class="{ 'is-invalid': submitted && form.last_name === '' }"
                      />
                      <div v-if="submitted && form.last_name === ''" class="invalid-feedback">
                        <span v-if="form.last_name === ''">surname is required!</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="validationCustom02">Name</label>
                      <input
                          id="validationCustom02"
                          v-model="form.first_name"
                          type="text"
                          class="form-control"
                          placeholder="Name"
                          required
                          :class="{ 'is-invalid': submitted && form.first_name === '' }"
                      />
                      <div v-if="submitted && form.last_name === ''" class="invalid-feedback">
                        <span>Name is required!</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Date of birth</label>
                      <date-picker v-model="form.birthday"
                                   type="date"
                                   value-type="format"
                                   format="YYYY-MM-DD"
                                   :input-attr="{ required: 'required', id: 'birthday'}"
                      ></date-picker>
                      <div v-if="submitted && form.birthday === ''" class="invalid-feedback">
                        <span>Date of birth is required!</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="validationCustom06">Country of birth</label>
                      <input
                          id="validationCustom06"
                          v-model="form.country_of_birth"
                          type="text"
                          class="form-control"
                          placeholder="Country of birth"
                          required
                          :class="{ 'is-invalid': submitted && form.country_of_birth === '' }"
                      />
                      <div v-if="submitted && form.country_of_birth === ''" class="invalid-feedback">
                        <span v-if="form.country_of_birth === ''">Country of birth is required!</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="validationCustom07">Country of residence</label>
                      <input
                          id="validationCustom07"
                          v-model="form.country_of_residence"
                          type="text"
                          class="form-control"
                          placeholder="Country of residence"
                          required
                          :class="{ 'is-invalid': submitted && form.country_of_residence === '' }"
                      />
                      <div v-if="submitted && form.country_of_residence === ''" class="invalid-feedback">
                        <span v-if="form.country_of_residence === ''">Country of residence is required!</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="validationCustom08">Language</label>
                      <input
                          id="validationCustom08"
                          v-model="form.language"
                          type="text"
                          class="form-control"
                          placeholder="Language"
                          required
                          :class="{ 'is-invalid': submitted && form.language === '' }"
                      />
                      <div v-if="submitted && form.language === ''" class="invalid-feedback">
                        <span v-if="form.language === ''">Language is required!</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="validationCustom09">Email</label>
                      <input
                          id="validationCustom09"
                          v-model="form.personal_email"
                          type="email"
                          class="form-control"
                          placeholder="Email"
                          required
                          :class="{ 'is-invalid': submitted && form.personal_email === '' }"
                      />
                      <div v-if="submitted && form.personal_email === ''" class="invalid-feedback">
                        <span v-if="form.personal_email === ''">Email is required!</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="validationCustom10">Phone</label>
                      <input
                          id="validationCustom10"
                          v-model="form.phone"
                          type="text"
                          class="form-control"
                          placeholder="Phone"
                          required
                          :class="{ 'is-invalid': submitted && form.phone === '' }"
                      />
                      <div v-if="submitted && form.phone === ''" class="invalid-feedback">
                        <span v-if="form.phone === ''">Phone is required!</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-5 mb-5">
              <div class="col-md-6 mt-2">SOCIAL NETWORKING PROFILES</div>
              <div class="col-md-6 text-right">
                <button class="btn btn-primary" type="button" @click="addSocialNetwork()">Add</button>
              </div>
              <div class="col-md-12 mt-3" v-if="form.social_networks.length > 0">
                <div class="row mt-1" v-for="(network, index) in form.social_networks" :key="`network-${index}`">
                  <div class="col-md-2 mt-2">
                    Link to profile
                  </div>
                  <div class="col-md-10">
                    <input
                        v-model="network.link"
                        type="text"
                        class="form-control float-left"
                        placeholder="add link to profile"
                        required
                        style="max-width: 850px"
                    />
                    <i class="bx bxs-trash float-right font-size-24 pt-1" @click="removeSocialNetwork(index)"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="validationCustom06">CURRICULUM VITAE</label>
                  <b-form-textarea
                      id="validationCustom06"
                      placeholder="Please write some information about you: your family, education, work experience, lifestyle etc. (not necessary)"
                      rows="5"
                      v-model="form.curriculum"
                      required
                  />
                  <div v-if="submitted && form.curriculum === ''" class="invalid-feedback">
                    <span v-if="form.curriculum === ''">Curriculum is required!</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                <span
                    class="input-group-text"
                >Personal document (Choose passport or ID card)</span
                >
                  </div>
                  <b-form-file
                      id="passport-file-id"
                      v-model="form.passport"
                      placeholder="Choose file"
                      :class="{ 'is-invalid': submitted && form.passport === '' }"
                  ></b-form-file>
                  <div v-if="submitted && form.passport === ''" class="invalid-feedback float-left">
                    <span>Passport or ID card is required!</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <b-form-checkbox
                  v-model="form.data_responsibility"
                  value="1"
                  unchecked-value="0"
                  class="custom-checkbox mb-3"
                  checked
              >I take responsibility for the accuracy of the data</b-form-checkbox>
            </div>
            <button class="btn btn-primary mt-5" v-if="form.data_responsibility" type="submit">Submit form</button>
          </form>
        </div>
        <div v-else class="card-body">
          <h4 v-if="!token || wrongToken">The link is not valid, please contact the HR department.</h4>
          <h4 v-if="isFilled" style="margin-bottom: 0;">The questionnaire has been successfully completed, thank you!</h4>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {convertObjectToFormData} from "../../../utils/converters";
import AvatarUploader from '@/components/AvatarUploader';

export default {
  name: "FillProfileShortly",
  components: {
    DatePicker,
    AvatarUploader
  },
  data() {
    return {
      submitted: false,
      isFilled: false,
      wrongToken: false,
      form: {
        first_name: "",
        last_name: "",
        birthday: "",
        country_of_birth: "",
        country_of_residence: "",
        phone: "",
        personal_email: "",
        language: "",
        curriculum: "",
        photo: null,
        passport: null,
        data_responsibility: 0,
        social_networks: [],
      },
    }
  },
  mounted() {
    this.$store.dispatch('offer/getProfile', this.token).then((res) => {
      if (res.data === null) {
        this.wrongToken = true;
      }
    });
  },
  computed: {
    profile() {
      return this.$store.state.offer.profile;
    },
    token() {
      return this.$route.query.token;
    },
    loading() {
      return this.$store.state.offer.loading;
    }
  },
  methods: {
    addSocialNetwork() {
      this.form.social_networks.push({
        link: '',
      });
    },
    removeSocialNetwork(index) {
      this.form.social_networks.splice(index, 1);
    },
    async handleSubmit() {
      event.preventDefault();
      this.submitted = true;

      const fData = new FormData();
      fData.append('token', this.token);
      fData.append('last_name', this.form.last_name);
      fData.append('first_name', this.form.first_name);
      fData.append('data_responsibility', this.form.data_responsibility);
      fData.append('birthday', this.form.birthday);
      fData.append('country_of_birth', this.form.country_of_birth);
      fData.append('country_of_residence', this.form.country_of_residence);
      fData.append('personal_email', this.form.personal_email);
      fData.append('phone', this.form.phone);
      fData.append('curriculum', this.form.curriculum);

      this.form.social_networks.forEach((item, index) => {
        fData.append(`social_networks[${index}][link]`, item.link);
      });

      if (this.form.photo) {
        await this.$store.dispatch('offer/uploadProfileDocument', {
          type: 'photo',
          reqData: convertObjectToFormData({
            token: this.token,
            document: this.form.photo,
            name: this.form.photo.name
          })
        });
      }

      if (this.form.passport) {
        await this.$store.dispatch('offer/uploadProfileDocument', {
          type: 'passport',
          reqData: convertObjectToFormData({
            token: this.token,
            document: this.form.passport,
            name: this.form.passport.name
          })
        });
      }

      await this.$store.dispatch('offer/updateProfile', {
        data: fData
      }).then(() => {
        this.isFilled = true;
      });
    },

    handleAvatarChange(image) {
      this.form.photo = image;
    },
  },
  watch: {
    async profile(value) {
      if (value) {
        this.form.first_name = value.first_name ?? '';
        this.form.last_name = value.last_name ?? '';
        this.form.birthday = value.birthday ?? '';
        this.form.country_of_birth = value.country_of_birth ?? '';
        this.form.country_of_residence = value.country_of_residence ?? '';
        this.form.language = value.language ?? '';
        this.form.phone = value.phone ?? '';
        this.form.personal_email = value.personal_email ?? '';
        this.form.curriculum = value.curriculum ?? '';
        this.form.social_networks = value.social_networks.map(({ link }) => ({link}));
      }
    }
  },
}
</script>

<style scoped>

</style>
